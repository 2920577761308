import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";

//import BlogRoll from '../components/BlogRoll'

import Layout from "../components/Layout/Layout";
import Navigation from "../components/Layout/Navigation";
// Eventhough not used directly (as a Gatsby module), this will overwrite any global styles
import IndexStyles from "./styles/index-page.scss";

// Images
import developing_code from "../img/layout/developing_code.svg";
import neogovProducts from "../img/layout/neogov-platform-full.png";
import mind_map from "../img/layout/mind_map.svg";
import do_code1 from "../img/layout/do_code1.svg";
import hiker_man_colour from "../img/layout/hiker-man-colour.svg";

export const IndexPageTemplate = ({
  title,
  subtitle,
  joinTheTeam,
  ourStack,
  offices,
  looksInteresting,
}) => (
  <React.Fragment>
    <div className="intro">
      <Navigation />

      <div className="container">
        <div className="row flex-md-row flex-sm-column mt-5 intro-row">
          <div className="col-md-4 info-column">
            <h1
              className="company-title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <p className="scompany-subtitle">{subtitle}</p>

            <Link to="/about" className="btn learn-more-button">
              Learn more
            </Link>
          </div>
          <div className="col image-column">
            <img src={developing_code} alt="Developing code" />
          </div>
        </div>
      </div>
    </div>

    <section className="container-fluid main-container">
      <div className="container section-wrap">
        <div className="join-the-team-card">
          <div className="image-wrapper neogov-products">
            <img src={neogovProducts} alt="Hiker" />
          </div>
          <div className="content-wrapper">
            <h1 className="join-the-team-title">{joinTheTeam.title}</h1>
            <p className="join-the-team-subtitle">{joinTheTeam.subtitle}</p>
          </div>
        </div>
      </div>

      <section className="container our-stack-container">
        <div className="row flex-md-row flex-sm-column">
          <div className="col-md-6 col-sm-auto stack-description">
            <h2>{ourStack.title}</h2>
            <p>{ourStack.subtitle}</p>
            <div className="pills">
              <div className="pill">
                <span>asp.net</span>
              </div>
              <div className="pill">
                <span>mssql</span>
              </div>
              <div className="pill">
                <span>angular</span>
              </div>
              <div className="pill">
                <span>flutter</span>
              </div>
              <div className="pill">
                <span>java</span>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-auto stack-image">
            <img className="d-block mx-auto mx-md-0" src={mind_map} />
          </div>
        </div>
      </section>
    </section>

    <section
      className="container-fluid our-offices-container"
      style={{
        backgroundImage: `url(${do_code1})`,
        backgroundPosition: `right 260px bottom -30px`,
        backgroundSize: `400px`,
        backgroundRepeat: `no-repeat`,
      }}
    >
      <section className="container">
        <div className="row d-flex flex-column">
          <h2 className="text-center mx-auto">{offices.title}</h2>
          <p className="text-center mx-auto">{offices.subtitle}</p>
        </div>

        <div className="card-deck office-cards">
          <div className="card">
            <Img
              style={{ width: "100%", height: "200px" }}
              className="card-img-top"
              fluid={offices.zagreb.image.image.childImageSharp.fluid}
              alt={offices.zagreb.image.image.alt}
            />
            <div className="card-body">
              <h5 className="card-title">Zagreb</h5>
              <p
                className="card-contact-info"
                dangerouslySetInnerHTML={{ __html: offices.zagreb.contactInfo }}
              />
            </div>
          </div>
          <div className="card">
            <Img
              style={{ width: "100%", height: "200px" }}
              className="card-img-top"
              fluid={offices.split.image.image.childImageSharp.fluid}
              alt={offices.split.image.image.alt}
            />
            <div className="card-body">
              <h5 className="card-title">Split</h5>
              <p
                className="card-contact-info"
                dangerouslySetInnerHTML={{ __html: offices.split.contactInfo }}
              />
            </div>
          </div>
        </div>
      </section>
    </section>

    <section className="container-fluid">
      <section className="container looks-interesting-container">
        <div className="row flex-md-row flex-sm-column">
          <div className="col-md-6 image-wrapper">
            <img className="d-block mx-auto mx-md-0" src={hiker_man_colour} />
          </div>
          <div className="col-md-5 content-wrapper">
            <h1>{looksInteresting.title}</h1>
            <p>{looksInteresting.description}</p>
            <a href="/careers#openings">See open Positions at NEOGOV</a>
            <br />
            <a href="/careers">Explore our employee benefits</a>
            <br />
            <a href="/about">Learn more about the company</a>
          </div>
        </div>
      </section>
    </section>
  </React.Fragment>
);

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  joinTheTeam: PropTypes.object,
  ourStack: PropTypes.object,
  offices: PropTypes.object,
  looksInteresting: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        joinTheTeam={frontmatter.joinTheTeam}
        ourStack={frontmatter.ourStack}
        offices={frontmatter.offices}
        looksInteresting={frontmatter.looksInteresting}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        subtitle
        joinTheTeam {
          title
          subtitle
        }
        ourStack {
          title
          subtitle
        }
        offices {
          title
          subtitle
          zagreb {
            description
            contactInfo
            image {
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 526, quality: 92) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          split {
            description
            contactInfo
            image {
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 526, quality: 92) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        looksInteresting {
          title
          description
        }
      }
    }
  }
`;
