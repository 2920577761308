import React from "react";
import { Link } from "gatsby";
import logo from "../../img/NEOGOV-Logo-RGB.png";

export default class Navigation extends React.Component {
  state = {
    navCollapsed: true
  };

  _onToggleNav = () => {
    this.setState({ navCollapsed: !this.state.navCollapsed });
  };

  render() {
    const { navCollapsed } = this.state;

    return (
      <nav className="navbar navbar-dark navbar-expand-md container">
        <Link className="navbar-brand" to="/" activeClassName="active">
          <img src={logo} alt="Neogov Logo" style={{ width: "200px" }} />
        </Link>
        <button
          onClick={this._onToggleNav}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarDefault"
          aria-controls="navbarDefault"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div
          className={
            (navCollapsed ? "collapse" : "") + " navbar-collapse mr-10"
          }
          id="navbarDefault"
        >
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/careers" activeClassName="active">
                Careers
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about" activeClassName="active">
                About us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
