import React from "react";
import { StaticQuery, graphql } from "gatsby";

import logo from "../../img/NEOGOV-Logo-RGB.png";

export default () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        file(name: { eq: "footer" }) {
          childMarkdownRemark {
            frontmatter {
              description
              legal_description
            }
          }
        }
      }
    `}
    render={data => (
      <footer>
        <div className="container">
          <div className="row flex-md-row flex-sm-column footer-row">
            <div className="col-md-3 logo-column">
              <img src={logo} alt="logo" />
            </div>
          </div>

          <hr />

          <div className="row flex-md-row flex-sm-column footer-row">
            <div className="col-md-3 logo-column">
              <div
                className="contact-info-wrapper"
                dangerouslySetInnerHTML={{
                  __html: data.file.childMarkdownRemark.frontmatter.description
                }}
              />
            </div>

            <div className="col-md-3" />

            <div className="col-md-6">
              <div
                className="legal-description"
                dangerouslySetInnerHTML={{
                  __html:
                    data.file.childMarkdownRemark.frontmatter.legal_description
                }}
              />
            </div>
          </div>
        </div>
      </footer>
    )}
  />
);
