import React from "react";
import Helmet from "react-helmet";
import Footer from "./Footer";
import "../all.scss";
import useSiteMetadata from "../SiteMetadata";

const TemplateWrapper = ({ pageTitle, pageDescription, children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <React.Fragment>
      <Helmet>
        <html lang="en" />
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>{pageTitle || title}</title>
        <link rel="icon" href="/img/favicon.png" />
        <meta name="description" content={pageDescription || description} />
        <meta name="og:image" content="/img/NEOGOV-Logo-RGB.png"/>
      </Helmet>

      {children}
      <Footer />
    </React.Fragment>
  );
};

export default TemplateWrapper;
